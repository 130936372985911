import React from 'react';
import tw from 'twin.macro';

import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import Header from '@components/Header';
import CookieBanner from '@components/Cookies/CookieBanner';
import TextRich from '@components/TextRich';


const Hero = tw.div`flex justify-center items-center text-xl`;
const SectionContainer= tw.section`p-5 pt-20 md:p-32 z-0`;


const HeroImage = tw.div`relative z-10 w-full max-w-full lg:max-w-screen-md overflow-hidden shadow-xl`;
const HeroHeading = tw.h1`font-serif font-semibold text-center pb-4 text-postheader text-lg md:text-5xl`;
const ArticleBody = tw.article`relative pt-4 pb-1 pl-2`;

/*
.hero {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 12.5em;
  background: #e1e1e1;
  margin: -1em -2.5em 1em;
  font-size: 2em
}
  */


export const query =  graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "DD MMMM, YYYY", locale: "es")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        json
      }
    }
  }
`;


const Post = ({pageContext, location}) => {
  

    const { article, mailchimp, next } = pageContext;
    //const post = props.data.contentfulBlogPost;
    //const siteTitle = props.data.site.siteMetadata.title;

    const hasHeroImage =  article.heroImage &&
                          Object.keys(article.heroImage.full).length !== 0 &&
                          article.heroImage.full.constructor === Object;

    return (
      <Layout>
        <Header />
        <SectionContainer id="home">


            <Helmet title={`${article.title}`} />
            <HeroHeading>{article.title}</HeroHeading>
            <Hero>
            <HeroImage id="ArticleImage__Hero">
              {hasHeroImage ? (
                <img src={article.heroImage.full.src} alt={article.title}/>
              ) : (<></>) }
            </HeroImage>
                    
            </Hero>
            <ArticleBody>
              <TextRich json={article.body}/>
              </ArticleBody>
        </SectionContainer>    
        <CookieBanner />
      </Layout>
    );
}


export default Post;